import React, { useEffect } from "react";
import "./AboutUs.css";
import GGTeamPhoto from "./GuardianGroupTeamPhoto.png";

const AboutUs = () => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      // Get Window Bottom
      const windowBottom = window.scrollY + window.innerHeight;
      const photo = document.querySelector(".image-content img");
      const photoTop = photo.offsetTop;
      if (
        windowBottom > photoTop + 200 &&
        !photo.classList.contains("visible")
      ) {
        photo.classList.add("visible");
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <div className="AboutUs">
      <div className="content-container">
        <div className="text-content">
          <div className="header">ABOUT US</div>
          <div className="subtitle">
            The Guardian Group redefines service...
          </div>
          <div className="about">
            <p>
              Welcome to Guardian Group, your trusted partner in Northeast
              Florida real estate. Our team of knowledgable experts brings
              extensive experience, unwavering commitment, and utmost
              professionalism, helping you to navigate the real estate market
              with ease and confidence.
              <p>
                At Guardian Group, we seek to make our customers feel like
                family. Well-versed in the intricacies of the industry, our
                seasoned veterans are equipped with in-depth market knowledge,
                and maintain a commitment to delivering outstanding results on
                either side of the transaction. We prioritize your goals.
                Whether you're looking to purchase a luxury home, get the keys
                to your first property, invest in real estate, or sell your
                current assets, Guardian Group can meet your needs.
              </p>
            </p>
          </div>
        </div>
        <div className="image-content">
          <img src={GGTeamPhoto}></img>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
