import React from "react";
import "./Toolbar.css";
import KeyIcon from "./Key.png";
import LogoLeft from "./Logo Left.png";
import LogoRight from "./Logo Right.png";

const Toolbar = () => {
  const goToAboutUs = () => {
    document
      .querySelector(".AboutUs")
      .scrollIntoView({ behavior: "smooth", duration: 5000 });
  };

  const goToOurTeam = () => {
    document
      .querySelector(".OurTeam")
      .scrollIntoView({ behavior: "smooth", duration: 5000 });
  };

  const goToFooter = () => {
    document
      .querySelector(".Footer")
      .scrollIntoView({ behavior: "smooth", duration: 5000 });
  };

  return (
    <nav className="Toolbar">
      <a className="link active">HOME</a>
      <a className="link" onClick={goToAboutUs}>
        ABOUT US
      </a>
      <div className="logo">
        <img className="logo" src={LogoLeft} />
        <img className="logo key" src={LogoRight} />
      </div>
      <a className="link" onClick={goToOurTeam}>
        OUR TEAM
      </a>
      <a className="link" onClick={goToFooter}>
        CONTACT
      </a>
    </nav>
  );
};

export default Toolbar;
