import logo from "./logo.svg";
import "./App.css";
import Toolbar from "./Toolbar";
import Hero from "./sections/Hero";
import AboutUs from "./sections/AboutUs";
import Footer from "./sections/Footer";
import OurTeam from "./sections/OurTeam";

function App() {
  return (
    <div className="App">
      <div className="page-content">
        <Hero></Hero>
        <AboutUs></AboutUs>
        <OurTeam></OurTeam>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
