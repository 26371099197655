import React, { useState, useEffect } from "react";
import TeamMember from "./TeamMember";
import "./OurTeam.css";
import Brad from "./Brad.png";
import Vicki from "./Vicki.png";
import Liz from "./Liz.png";
import Gary from "./Gary.png";

const OurTeam = () => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      // Get Window Bottom
      const windowBottom = window.scrollY + window.innerHeight;
      const photo = document.querySelector(".carousel");
      const photoTop = photo.offsetTop;
      if (windowBottom > photoTop + 100) {
        photo.classList.remove("hidden");
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  const [team, setTeam] = useState([
    {
      name: "Brad Sykes",
      title: "CO-FOUNDER",
      img: Brad,
      email: "bsykes2819@gmail.com",
      phone: "904.328.9617",
    },
    {
      name: "Vicki Sykes",
      title: "CO-FOUNDER",
      img: Vicki,
      email: "vicki.sykes@att.net",
      phone: "904.772.5970",
    },
    {
      name: "Elizabeth Bailey",
      title: "AGENT",
      img: Liz,
      email: "elizabethbailey@kw.com",
      phone: "904.710.1706",
    },

    {
      name: "Gary Hackney",
      title: "AGENT",
      img: Gary,
      email: "garyhackney@kw.com",
      phone: "352.538.4954",
    },
  ]);
  return (
    <div className="OurTeam">
      <div className="carousel hidden">
        {team.map((member) => (
          <TeamMember
            name={member.name}
            title={member.title}
            img={member.img}
            email={member.email}
            phone={member.phone}
          ></TeamMember>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
