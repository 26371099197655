import React from "react";
import "./Hero.css";
import VideoPng from "./Video.png";
import Toolbar from "../Toolbar";

const Hero = (props) => {
  return (
    <div className="Hero">
      <Toolbar />
      <div className="img-content">
        <img className="image" src={VideoPng}></img>
        <div className="gradient"></div>
      </div>
      <div className="text-body">
        <div className="text-content">
          <div className="header">GUARDIAN GROUP</div>
          <div className="subtitle">
            Your dream home is just around the corner.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
