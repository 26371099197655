import React from "react";
import "./TeamMember.css";

const TeamMember = (props) => {
  return (
    <a className="TeamMember" href={`mailto:${props.email}`}>
      <img src={props.img}></img>
      <div className="member-data">
        <div className="name">{props.name}</div>
        <div className="title">{props.title}</div>
        <div className="contact">
          <a className="contact-text" href={`mailto:${props.email}`}>
            {props.email}
          </a>
        </div>
        <div className="contact">
          <a className="contact-text" href={`tel:${props.phone}`}>
            {props.phone}
          </a>
        </div>
      </div>
    </a>
  );
};

export default TeamMember;
