import React from "react";
import "./Footer.css";
import Instagram from "./instagram.png";
import Facebook from "./facebook.png";
import Twitter from "./twitter.png";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="main-contact-container">
        <div className="contact-logo-container">
          <div className="contact-logo">
            <div className="gg">GG</div>
            <div className="gg-text">Guardian Group</div>
          </div>
          <div className="social-media">
            <a className="button" href="/">
              <img className="instagram" src={Instagram}></img>
            </a>
            <a
              className="button"
              href="https://www.facebook.com/guardiangroup1"
            >
              <img className="facebook" src={Facebook}></img>
            </a>
            <a className="button" href="/">
              <img className="twitter" src={Twitter}></img>
            </a>
          </div>
        </div>
        <div className="contact-info-container">
          <div className="email">
            <div className="header">EMAIL</div>
            <a className="info" href="mailto:vicki.sykes@att.net">
              vicki.sykes@att.net
            </a>
          </div>
          <div className="email">
            <div className="header">PHONE</div>
            <a className="info" href="tel:9047725970">
              904.772.5970
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
